<template>
  <div class="relative"  @mouseover="isOpen=true" @mouseleave="isOpen=false">
      <slot></slot>
      <div class="pt-2">
          <div v-if="isOpen"  class="dropdown-list absolute mt-[0px]  z-20 group w-60 py-2 px-2 text-sm text-gray-700 rounded-lg border bg-white transition-all">
           <slot name="item"></slot>
         </div>
      </div>
  </div>
</template>

<script setup>
// Reactive state
const isOpen = ref(false);
</script>


